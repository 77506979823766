






















import { Component, Prop, Vue } from 'vue-property-decorator'
import { MailingListPageStore } from '@elitepage/store/mailing-list-page'
import PMailingListSignup from '@elitepage/components/PMailingListSignup.vue'

@Component({ components: { PMailingListSignup } })
export default class MailingListPage extends Vue {
    @Prop({ type: String }) producerSlug!: IProducer['slug']

    @MailingListPageStore.State('mailingListPage') mailingListPage: IElitepage

    @MailingListPageStore.Action('loadMailingListPage') loadMailingListPage: (producerSlug: IProducer['slug']) => void

    async mounted() {
        await this.loadMailingListPage(this.producerSlug)
    }
}
