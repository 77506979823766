
















import { Component, Prop, Vue } from 'vue-property-decorator'
import { MailingListPageStore } from '@elitepage/store/mailing-list-page'
import { subscribeToMailingList } from '@elitepage/api/mailchimp'
import { ElitepageStore } from '@elitepage/store/elitepage'

@Component
export default class PMailingListSignup extends Vue {
    @Prop({ type: Boolean, default: false }) isForMailingListPage: boolean

    @MailingListPageStore.State('mailingListPage') mailingListPage: IElitepage
    @ElitepageStore.State('producer') producer: IProducer

    email = ''
    name = ''
    successOnSubscription = false
    errorOnSubscription = ''

    async subscribe() {
        try {
            await subscribeToMailingList({ producer_id: this.producer.id, email: this.email, name: this.name })
            this.successOnSubscription = true
        } catch (error: any) {
            this.errorOnSubscription = error
        }
    }
}
