import { render, staticRenderFns } from "./PMailingListSignup.vue?vue&type=template&id=ebd31bd6&scoped=true&lang=pug&"
import script from "./PMailingListSignup.vue?vue&type=script&lang=ts&"
export * from "./PMailingListSignup.vue?vue&type=script&lang=ts&"
import style0 from "./PMailingListSignup.vue?vue&type=style&index=0&id=ebd31bd6&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebd31bd6",
  null
  
)

export default component.exports